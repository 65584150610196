import './index.scss';

const PageNotFound = () => {
    return (
        <div className="pageNotFound">
            PageNotFound
        </div>
    );
};

export default PageNotFound;