import React from "react";

const ArrowTopRight = () => {
    return (
        <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 34.2 32.3" xmlSpace="preserve"
             style={{strokeWidth: 2}}>
            <line x1="0" y1="16" x2="33" y2="16"></line>
            <line x1="17.3" y1="0.7" x2="33.2" y2="16.5"></line>
            <line x1="17.3" y1="31.6" x2="33.5" y2="15.4"></line>
        </svg>
    );
}

export default ArrowTopRight;